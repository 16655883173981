<template>
  <div>
    <v-card outlined class="mb-4">
      <v-card-title>{{ $t("Icon") }}</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="4" lg="3">
            <v-autocomplete
              v-if="allIcons"
              :append-outer-icon="page.Icon"
              :label="$t('Select an icon')"
              hide-details="auto"
              v-model="page.Icon"
              :loading="loading"
              :items="allIcons"
              item-value="icon"
              item-text="name"
              class="add-item-select select-width"
              single-line
              outlined
              dense
              flat
            >
              <template v-slot:item="{ item }">
                <v-icon color="primary">
                  {{ item.icon }}
                </v-icon>
                <span class="ml-2">
                  {{ item.name }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import json from "../../locale/Icons";

export default {
  props: {
    page: {
      type: [Array, Object],
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      allIcons: json.icons(),
    };
  },
};
</script>

<style>
</style>